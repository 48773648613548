import React, { useContext } from 'react';
import { UserContext } from "../UserContext";
import styled from 'styled-components';
import ProgressNav from '../components/ProgressNav'
import Card from '../components/Card'
import Button from '../components/Button';
import Calendar from '../components/Calendar';

const Section = styled.div`
  width:100vw;
  height:100vh;
  display:flex;
  flex-direction:column;
`;

const Main = styled.main`
  max-width:420px;
  margin:0 auto;
  text-align:center;
  div .form {
    padding:20px !important;
  }
  @media screen and (max-width:500px) {
    h2 {
      font-size:22px;
    }
  }
  @media screen and (max-width:400px) {
    min-width:100%;
    max-width:100%;
    h2 {
      font-size:18px;
    }
  }
`;

const TimePage = () => {
  const {startTime, endTime} = useContext(UserContext);

  return(
    <Section>
      <ProgressNav step={3} />
      <Main className="mw">
        <div className="ap">
          <h2>Boka in ett tilfälle som passar!</h2>
        </div>
        <Card>
          <form>
            <Calendar />
            <Button text="Bekräfta" after={true} disabled={!startTime && !endTime} linkTo="/details" />
          </form>
        </Card>
      </Main>
    </Section>
  );
}

export default TimePage