import React, { useState, useContext } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import ProgressNav from '../components/ProgressNav'
import Card from '../components/Card'
import Button from '../components/Button';
import InputField from '../components/InputField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { UserContext } from "../UserContext";
import { useHistory } from "react-router-dom";
import {post} from '../utils/fetch'

const Section = styled.div`
  width:100vw;
  height:100vh;
`;

const Main = styled.main`
  max-width:400px;
  margin:0 auto;
  input.half {
    display:inline-block;
    width: calc(50% - 10px);
    &.l {
      margin-right:20px !important;
    }
  }
  textarea {
    min-height:50px;
    border-radius:2px;
    font-family:arial;
  }
`;

const ErrorMessage = styled.p`
  text-transform: capitalize;
  font-size:13px;
  margin-top:20px;
  color: ${props => props.theme.colors.danger};
`;

const SuccessMessage = styled.p`
  text-transform: capitalize;
  font-size:12px;
  color: ${props => props.theme.colors.success};
`;

const CheckOutPage = () => {
  var [email, setEmail] = useState("");
  var [fname, setFName] = useState("");
  var [companyName, setCompanyName] = useState("");
  var [companyNumber, setCompanyNumber] = useState("");
  var [lname, setLName] = useState("");
  var [postal, setPostal] = useState("");
  var [street, setStreet] = useState("");
  var [number, setNumber] = useState("");
  var [comment, setComment] = useState("");
  var [radioValue, setRadio] = useState(false);
  var [error, setError] = useState();
  var [success, setSuccess] = useState("");
  
  const { setCustomer } = useContext(UserContext);
  const history = useHistory();
  
  async function handleSubmit() {
    let customer:any;   // either person or company depending on radio value
    let response:any;

    // Error handling
    if (radioValue && companyNumber.length < 10) {
      setError("Ange ett giltigt orgnummer")
      return;
    }
    if (number.length < 5) {
      setError("Ange ett giltigt telefonnummer")
      return;
    }

    if (radioValue) {
      customer = {
        "company_name": companyName,
        "company_number": companyNumber,
        "name": fname + " " + lname,
        "email": email,
        "phone_number": number,
        "adress": postal + " " + street,
        "comment": comment
      }
      setCustomer(customer, true)
      response = await post("company/", customer)
    }
    else {
      customer = {
        "name": fname + " " + lname,
        "email": email,
        "phone_number": number,
        "adress": postal + " " + street,
        "comment": comment
      }
      setCustomer(customer, false)
      response = await post("person/", customer)
    }
    
    if(response.status === "success") {
      setSuccess(response.message)
    }
    else {
      setError(response.message)
    }

    history.push("/confirm");
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (val && typeof val === "string") {
      if (val === "true") {
        setRadio(true)
        history.push("/details/company")
      }
      else {
        setRadio(false)
        history.push("/details")
      }
    }
  };
  
  return(
    <Section>
      <ProgressNav />
      <Main className="mw">
        <div className="ap">
          <h1>Kontaktuppgifter</h1>
        </div>
        <Card>
          <form>
            <FormControl component="fieldset" error={error}>
              <RadioGroup aria-label="personorcompany" name="personorcompany" value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel value={false} control={<Radio />} label={"Privatperson"} defaultChecked />
                <FormControlLabel value={true} control={<Radio />} label={"Företag"} />
              </RadioGroup>
            </FormControl>
            <Route path="/details/company" exact>
              <InputField type="text" placeholder="Företagsnamn" onChange={(e:any) => setCompanyName(e.target.value)} />
              <InputField type="number" placeholder="Organisationsnummer" onChange={(e:any) => setCompanyNumber(e.target.value)} />
            </Route>
            <InputField type="email" placeholder="Email" onChange={(e:any) => setEmail(e.target.value)} />
            <InputField className="half l" type="text" placeholder="Förnamn" onChange={(e:any) => setFName(e.target.value)} />
            <InputField className="half" type="text" placeholder="Efternamn" onChange={(e:any) => setLName(e.target.value)} />
            <InputField type="tel" placeholder="Telefonnummer" onChange={(e:any) => setNumber(e.target.value)} />
            <InputField className="half l" type="number" placeholder="Postnummer" onChange={(e:any) => setPostal(e.target.value)} />
            <InputField className="half" type="text" placeholder="Gatuadress" onChange={(e:any) => setStreet(e.target.value)} />
            <TextareaAutosize placeholder="Övrig kommentar (t.ex portkod)" onChange={(e:any) => setComment(e.target.value)} />

            {success ? <SuccessMessage>{success}</SuccessMessage> : <ErrorMessage>{error}</ErrorMessage>}
            <div onClick={() => handleSubmit()}>
              <Button text="Fortsätt" after={true} disabled={!(email && street && fname && lname) || (radioValue && (!companyNumber || !companyName))} />
            </div>
          </form>
        </Card>
      </Main>
    </Section>
  );
}

export default CheckOutPage