import React, { useContext } from "react";
import styled from "styled-components";
import Card from "../components/Card";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import { postFile } from "../utils/fetch";
import { UserContext } from "../UserContext";
import imageCompression from "browser-image-compression";

const Question = styled.h1`
  color: #0d0d0d;
  font-size: 28px;
  text-align: left;
  padding: 0 32px;
  @media screen and (max-width: 500px) {
    font-size: 24px;
    padding: 0 20px;
  }
`;

const QuizContainer = styled.div`
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  form {
    min-height: 100px;
    #fileToUpload {
      text-align: center;
      line-height: 20px;
      margin: 0 0 20px 0;
      font-size: 14px;
    }
  }
  button {
    display: inline-block;
    margin-top: 20px;
    float: right;
  }
  .preview {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 2px;
    object-fit: cover;
    margin-bottom: 8px;
    margin-right: 4px;
  }
  @media screen and (max-width:400px) {
    min-width:100%;
    max-width:100%;
  }
`;

const ErrorMessage = styled.p`
  color: ${(props) => props.theme.colors.danger};
`;

const ProgressSection = styled.div`
  margin-top: 32px;
  p {
    text-transform: uppercase;
    font-size: 11px;
    line-height: 20px;
    margin-bottom: 4px;
  }
`;

const ProgressBar = styled.div`
  position: relative;
  height: 1px;
  width: 100%;
  background-color: #eee;
`;

interface Props {
  width: string;
}

const Fill = styled.div<Props>`
  height: 100%;
  width: ${(props) => props.width};
  background-color: #3f51b5;
`;

const Btns = styled.div`
  margin-top: 26px;
`;

const BackBtn = styled.div`
  display: inline-block;
  color: rgba(0, 0, 0, 0.36);
  cursor: pointer;
  p {
    display: inline-block;
    line-height: 36px;
    font-size: 14px;
    color: inherit;
    text-transform: uppercase;
  }
  span {
    line-height: 36px;
    margin-right: 6px;
    font-size: 1.2em;
    color: inherit;
  }
  &:hover p {
    text-decoration: underline;
  }
`;

function getForm(
  question: any,
  classes: any,
  value: any,
  setValue: any,
  error: any,
  setError: any,
  answers: any,
  loading: boolean,
  setLoading: any,
  setSuccess: any,
  previews: any,
  setPreviews: any
) {
  const handleSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setError("");
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue([...value, event.target.value]);
    setError("");
  };

  async function handleMedia(e: React.ChangeEvent<HTMLInputElement>) {
    setError("");
    setSuccess("");

    if (
      e &&
      e.target &&
      e.target.files &&
      e.target.files[0] &&
      e.target.files.length > 0
    ) {
      const files: any = e.target.files;
      const nof: number = files.length;
      console.log("Files before fetch: ", files);
      let uploadedFiles: any = [];

      setLoading(true);
      setPreviews(files);
      for (var i = 0; i < nof; i++) {
        var f: any = files[i];

        if (f.size > 100 * 1024 * 1024) {
          // 2MB File limit until figured out why larger doesnt work on post requestgit
          setError("Filen är för stor");
        } else {
          if (f.type.includes("image")) {
            // Compress image
            console.log("originalFile instanceof Blob", f instanceof Blob); // true
            console.log(`originalFile size ${f.size / 1024 / 1024} MB`);
            console.log(
              "file will be",
              f.type.includes("image") ? "jpg" : "mp4"
            );
            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1280,
              useWebWorker: true,
              fileType: f.type.includes("image") ? "jpg" : "mp4",
            };
            try {
              const compressedFile = await imageCompression(f, options);
              console.log(
                "compressedFile instanceof Blob",
                compressedFile instanceof Blob
              ); // true
              console.log(
                `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
              ); // smaller than maxSizeMB
              f = compressedFile;
            } catch (error) {
              console.log(error);
              setError("Couldnt compress image");
            }
          }

          if (f) {
            console.log("Individual file before fetch: ", f);
            const result: any = await postFile("file/dryft-now-media", f);
            if (result.status === 200) {
              uploadedFiles.push(result);
              console.log("Individual file after fetch: ", f);
              setSuccess(
                "Klart! " +
                  nof.toString() +
                  (nof > 1 ? " filer uppladdade." : " fil uppladdad.")
              );
            } else {
              setError(
                "Något gick fel, vänligen pröva igen. " +
                  result +
                  " " +
                  f.name +
                  " " +
                  f.type
              );
            }
          }
        }
      }
      setValue(uploadedFiles);
      setLoading(false);
    } else {
      setError("Vänligen välj video och/eller bilder");
    }
  }

  let previousAnswer = answers.filter(
    (answer: any) => answer.question === question.question
  );
  if (previousAnswer[0]) previousAnswer = previousAnswer[0].answer;

  const type = question.type;

  switch (type) {
    case "radio":
      return (
        <form
          onSubmit={handleSubmit}
          method="post"
          encType="multipart/form-data"
        >
          <FormControl
            component="fieldset"
            error={error}
            className={classes.formControl}
          >
            <RadioGroup
              aria-label="quiz"
              name="quiz"
              value={value}
              onChange={handleChange}
            >
              {question.options.map((option: any) => {
                return (
                  <FormControlLabel
                    value={option}
                    control={
                      <Radio defaultChecked={previousAnswer === option} />
                    }
                    label={option}
                    defaultChecked={previousAnswer === option}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </form>
      );
    case "checkbox":
      return (
        <form onSubmit={handleSubmit}>
          <FormGroup onChange={handleCheckbox}>
            {question.options.map((option: any) => {
              return (
                <FormControlLabel
                  value={option}
                  control={
                    <Checkbox
                      name={option}
                      defaultChecked={previousAnswer === option}
                    />
                  }
                  label={option}
                  defaultChecked={previousAnswer === option}
                />
              );
            })}
          </FormGroup>
        </form>
      );
    case "text":
      return (
        <form onSubmit={handleSubmit}>
          <TextareaAutosize
            aria-label="empty textarea"
            onChange={(e: any) => handleChange(e)}
            defaultValue={previousAnswer ? previousAnswer : ""}
          />
        </form>
      );
    case "file":
      return (
        <form onSubmit={handleSubmit}>
          <input
            type="file"
            name="file"
            id="fileToUpload"
            multiple={true}
            accept="image/*, video/*"
            onChange={(e: any) => handleMedia(e)}
          />
          {previews ? (
            <div>
              {Object.keys(previews).map(function (key) {
                const img = previews[key];
                return (
                  <video
                    src={URL.createObjectURL(img)}
                    poster={URL.createObjectURL(img)}
                    className="preview"
                  />
                );
              })}
            </div>
          ) : (
            ""
          )}
          {loading ? <p>Laddar upp... Vänligen vänta</p> : ""}
        </form>
      );
  }
  console.log(value);
}

const QuizForm = ({
  question,
  questionIndex,
  length,
  nextQuestion,
  previousQuestion,
}: any) => {
  const { answers } = useContext(UserContext);

  const progress: number = Math.round((questionIndex / length) * 100);

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: 0,
      width: "100%",
    },
    button: {
      display: "inline-block",
      margin: "0 !important",
    },
  }));

  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [previews, setPreviews] = React.useState();
  const [loading, setLoading] = React.useState(false);

  return (
    <QuizContainer>
      <Question>{question.question}</Question>
      <Card>
        {getForm(
          question,
          classes,
          value,
          setValue,
          error,
          setError,
          answers,
          loading,
          setLoading,
          setSuccess,
          previews,
          setPreviews
        )}
        {error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : success ? (
          <p>{success}</p>
        ) : question.tips ? (
          <label htmlFor="fileToUpload">
            <p>Tips: {question.tips}</p>
          </label>
        ) : ""}
        <ProgressSection>
          <p>
            {questionIndex}/{length} frågor besvarade ({progress}%)
          </p>
          <ProgressBar>
            <Fill width={progress + "%"}></Fill>
          </ProgressBar>
        </ProgressSection>
        <Btns>
          <BackBtn onClick={previousQuestion}>
            <span className="material-icons">keyboard_backspace</span>
            <p>Tillbaka</p>
          </BackBtn>
          <Button
            type="submit"
            disabled={value === "" && question.type !== "file"}
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => {
              nextQuestion(value);
              setValue("");
              setError("");
              setSuccess("");
            }}
          >
            Nästa fråga
          </Button>
        </Btns>
      </Card>
    </QuizContainer>
  );
};

export default QuizForm;