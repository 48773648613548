import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  margin: 10px auto;
  padding: 0px 12px;
  line-height:36px;
  background-color: #f1f1f1;
  border: 1px solid #eee;
  border-radius:12px;
  &:focus {
    border: 1px solid #e1e1e1;
  }
`;
const InputField = ( {type, placeholder, onchange, ...rest} : any ) => {
  return (
    <Input type={type} placeholder={placeholder} onChange={onchange} {...rest} >

    </Input>
  );
}

export default InputField;
