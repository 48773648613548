import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import ProgressNav from '../components/ProgressNav'
import Card from '../components/Card'
import Button from '../components/Button';
import readableDate from '../utils/readableDate'
import {UserContext} from "../UserContext";
import {Redirect} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {get, post} from '../utils/fetch'

import SwishImg from '../assets/images/swish.png';
import FakturaImg from '../assets/images/faktura.svg';

const Section = styled.div`
  width:100vw;
  height:100vh;
`;

const Main = styled.main`
  max-width:420px;
  margin:0 auto;
`;

const Receipt = styled.form`
  * {
    user-select:text;
  }
  section {
    margin-bottom:8px;
    padding-bottom:8px;
    border-bottom:1px solid #e1e1e1;
  }
  ul {
    height:auto;
    transition: all 0.2s ease-in-out;
    overflow:hidden;
    padding-top:2px;
    padding-bottom:4px;
  }
  ul.hide {
    height:0;
  }
  ul span {
    display:inline-block;
  }
  li {
    display:block;
  }
  li, .right {
    font-size:15px;
  }
  .showanswers {
    cursor:pointer;
    * {
      user-select:none;
      line-height:25px;
    }
  }
  span.material-icons {
    text-align:right;
    font-size:19px;
  }
  li .question {
    line-height:1.2em;
    margin-top:4px;
    margin-bottom:2px;
  }
  li .answer {
    display:block;
    line-height:1.2em;
    padding-left:20px;
    font-style:italic;
  }
  .right {
    float:right;
  }
  .total {
    border-top:1px solid #f1f1f1;
    font-weight:600;
  }
`;

const PaymentMethods = styled.div`
  text-align:center;
  p {
    font-size:13px;
    margin-top:4px;
    margin-bottom:-17px;
    font-style:italic;
  }
  img {
    display:inline-block;
    height:46px;
    width:auto;
    object-fit:contain;
    padding:5px;
    user-drag: none; 
    user-select: none;
  }
  #faktura {
    opacity:0.62;
  }
`;

const ErrorMessage = styled.p`
  text-transform: capitalize;
  font-size:12px;
  color: ${props => props.theme.colors.danger};
`;

const CheckOutPage = () => {
  var [error, setError] = useState("");
  var [loading, setLoading] = useState(false);
  var [pricing, setPrice]:any = useState();
  var [showAnswers, setShowAnswers] = useState(false);
  let price:any; //  Total
  let VAT:any
  let ROT:any

  const {answers, startTime, endTime, customer, service, setTicket, operator} = useContext(UserContext);
  const history = useHistory();

  async function getPrice() {
    const p = await get("price/")
    setPrice(p)
  }
  if (!pricing) {
    getPrice()
  }
  else if (customer) {
    if (customer.company) {
      price = pricing.total.total;      // Excluding vat
    }
    else {
      price = pricing.total.total_VAT_ROT;  // Including vat
      VAT = price - pricing.total.total;
      ROT = pricing.total.total_VAT - pricing.total.total_VAT_ROT;
    }
  }

  async function handleSubmit() {
    setLoading(true)
    const answers_refactored = answers.map(function (question:any, label:string) {
      let data;
      if (question.options) {
        data = {
          "options": question.options,
          "answer": question.answer
        }
      }
      else if (question.type === "file") {
        data = {
          "file": question.answer
        } 
      }
      else {
        data = {
          "answer": question.answer
        }
      }

      return {
        "type": question.type,
        "question": question.question,
        "data": data
      };
    });

    answers_refactored.unshift({
      "type": "radio",
      "question": "Vem är du?",
      "data": {
        "answer": customer.email + ", " + customer.name + ", " + customer.phone_number
      },
    })

    answers_refactored.unshift({
      "type": "radio",
      "question": "Var bor du?",
      "data": {
        "answer": customer.adress
      },
    })

    answers_refactored.unshift({
      "type": "radio",
      "question": "Vilken tid vill du ha hjälp?",
      "data": {
        "answer": startTime
      },
    })

    const ticket = {
      "customer": {
        "email": customer.email
       },
      "operator": {
        "email": operator
      },
      "classification": {
        "service_type": service,
        "inputs": answers_refactored
      },
      "receipt": {
        "date": startTime,
        "pricing": pricing,
        "time": { //Fix in core not hardcode
          "transport": "1h",
          "arbete": "2h",
          "totalt": "3h"
        }
      }
    };
    setTicket(ticket)
    console.log(ticket)

    const ticket_content = await post("ticket/", ticket);

    const event:any = {
      "id": operator, // Temporarily for testing so that liam doesnt get booked
      "event": {
        "summary": "Dryft Now: " + service,
        "description": "Typ av service: "  + service + ", Uppskattad kostnad: " + price + " SEK",
        "start": {"dateTime": startTime},
        "end": {"dateTime": endTime},  
        "location": customer.adress,
      }
    }

    const calendar_content = await post("calendar/event", event);
    console.log(calendar_content)

    if(ticket_content.status===200) {
      if (!calendar_content.errors) {
        history.push("/success")
      }
      else {
        setError(calendar_content.message)
      }
    }
    else {
      setError(ticket_content.message)
    }
    setLoading(false)
  }
  
  return(
    <Section>
      <ProgressNav />
      <Main className="mw">
        <div className="ap">
          <h1>Bekräfta pris</h1>
        </div>
        <Card>
          {true /*customer && service && answers*/ ? 
          <Receipt>
            <section>
              <p>
                <strong>Uppskattat pris</strong>
              </p>
              {pricing ?
              <ul>
                <li><span>Minimidebitering 3h</span><span className="right">{pricing.summary.minimidebitering ? pricing.summary.minimidebitering + " kr" : "Inte bestämt"}</span></li>
                <li><span>Timkostnad efter 3h</span><span className="right">{pricing.summary.timkostnad ? "(" +pricing.summary.timkostnad + " kr)" : "Inte bestämt"}</span></li>
                <li><span>Material</span><span className="right">{pricing.summary.material ? pricing.summary.material + " kr" : "Inte bestämt"}</span></li>
                {customer && customer.company ? "" : <li><span>Moms</span><span className="right">{VAT ? VAT + " kr" : "Inte bestämt"}</span></li>}
                {customer && customer.company ? "" : <li><span>ROT-avdrag</span><span className="right">{ROT ?"- " + ROT + " kr" : "Inte bestämt"}</span></li>}
                <li className="total"><span>Totalt ({customer && customer.company ? "exkl. moms" : "inkl. moms"})</span><span className="right">{price ? price + " kr" : "Inte bestämt"}</span></li>
              </ul>
              : <p>Beräknar...</p> }
            </section>
            <section>
              <p>
                <strong>Datum</strong><span className="right">{startTime ? readableDate(startTime) + "-" + readableDate(endTime).slice(11,16) : "Inte bestämt"}</span>
              </p>
              <p>
                <strong>Tjänst</strong><span className="right">{service ? service : "Inte bestämt"}</span>
              </p>
              <p>
                <strong>Uppskattad tid</strong>
              </p>
              <ul>
                <li><span>Transport</span><span className="right">1h</span></li>
                <li><span>Arbete</span><span className="right">2h</span></li>
                <li className="total"><span>Totalt</span><span className="right">3h</span></li>
              </ul>
              <p onClick={() => setShowAnswers(!showAnswers)} className="showanswers">
                <strong>Dina svar</strong>
                <span className="right">{showAnswers ? "Dölj" : "Läs mer"}<span className="material-icons">{showAnswers ? "keyboard_arrow_down" : "keyboard_arrow_right"}</span></span>
              </p>
              <ul className={showAnswers ? "" : "hide"}>
                {answers.map((answer:any) => {
                  return <li><span className="question">{answer.question}</span><span className="answer">{answer.answer ? (answer.type==="file" ? answer.answer.length + (answer.answer.length > 1 ? " filer": " fil") : answer.answer) : "Ej angett"}</span></li>
                })}
              </ul>
            </section>
            <section>
              <p>
                <strong>Uppgifter ({customer && customer.company ? "företag" : "privatperson"})</strong>
              </p>
              <ul>
                <li><span>Namn</span><span className="right">{customer && customer.name ? customer.name : "Ej angett"}</span></li>
                <li><span>Adress</span><span className="right">{customer && customer.adress ? customer.adress : "Ej angett"}</span></li>
                <li><span>Email</span><span className="right">{customer && customer.email ? customer.email : "Ej angett"}</span></li>
                <li><span>Telefon</span><span className="right">{customer && customer.phone_number ? customer.phone_number : "Ej angett"}</span></li>
              </ul>
            </section>
            <PaymentMethods>
              <img src={SwishImg} alt="swish" />
              <img src={FakturaImg} alt="faktura" id="faktura" />
              <p>Betalning sker med Swish eller faktura på plats</p>
            </PaymentMethods>
            {error ? <ErrorMessage>{error}</ErrorMessage>:""}
              <Button text={loading ? "Laddar..." : "Bekräfta"} type="submit" after={true} disabled={loading} linkTo="#" func={handleSubmit} />
          </Receipt>
          :<Redirect to="/" />}
        </Card>
      </Main>
    </Section>
  );
}

export default CheckOutPage