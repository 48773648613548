import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Logo from './Logo'

const navHeight = 64;
const innerHeight = 40;
const margin = (navHeight-innerHeight)/2;

const Header = styled.div`
  position: relative;
  width: 100%;
  height: ${navHeight}px;
  h2 {
    display:inline-block;
  }
  .mw {
    height: ${navHeight}px;
    padding-top:${margin}px;
    padding-bottom:${margin}px;
  }
  p {
    float:right;
    line-height: ${innerHeight}px;
    font-size:16px;
  }
`;

const Nav = () => {
  return (
    <Header className="ap">
        <div className="mw">
          <Logo />
          <Link to="/about">
            <p>Om oss</p>
          </Link>
        </div>
    </Header>
  );
}

export default Nav;
