export const PRODUCTION = true;
const BASE_URL = PRODUCTION ? "https://dryft.tech/" : "http://localhost:5000/";

export async function post(end_point: string, body: any) {
  const response = await fetch(BASE_URL + end_point, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
  });
  const json = response.json();
  return json;
}

export async function postFile(end_point: string, f: any) {
  console.log("individual file inside fetch: ", f);
  console.log("individual file type inside fetch: ", f.type);
  console.log("individual end point inside fetch: ", BASE_URL + end_point);
  console.log(f instanceof Blob);
  console.log("TYPE", f.type);

  var headers = new Headers();
  headers.append("Access-Control-Allow-Origin", "*");
  // const response = await fetch(BASE_URL + end_point, {
  //   method: "POST",
  //   headers,
  //   // mode: "no-cors",
  //   body: f,
  // });
  const formData = new FormData();
  formData.append("inputFile", f);
  const response = await fetch(BASE_URL + end_point, {
    method: "POST",
    body: formData,
    headers,
  });
  console.log("fetch response", response);
  const json = response.json();
  console.log("fetch response json", json);
  return json;
}

export async function get(end_point: string) {
  const response = await fetch(BASE_URL + end_point, {
    method: "GET",
    headers: {
      Accept: "*/*",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return response;
}
