import React from "react";
import { ThemeProvider } from "styled-components";

interface IProps {
  children: React.ReactNode;
  // any other props that come into the component
}

const theme = {
  colors: {
    blue: "#007bff",
    indigo: "#6610f2",
    purple: "#6f42c1",
    pink: "#e83e8c",
    red: "#dc3545",
    orange: "#fd7e14",
    orangeOther: "#ff7d48",
    yellow: "#ffc107",
    green: "#21ce99",
    teal: "#20c997",
    cyan: "#17a2b8",
    gray: "#6c757d",
    dark: "#343a40",
    success: "#28a745",
    warning: "#ffc107",
    danger: "#dc3545",
    light: "#f8f9fa",
  }
};

const Theme = ({ children }: IProps) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;