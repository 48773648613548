import React, { Component, createContext } from "react";

type userState = {
  service: string;
  setService: any;
  questionIndex: number;
  setQuestionIndex: any;
  answers: any;
  storeAnswer: any;
  startTime: string;
  endTime: string
  setTime: any;
  customer: any,
  company: boolean;
  setCustomer: any,
  ticket: any;
  setTicket: any;
  operator: string,
  setOperator:any,
};

export const UserContext = createContext<userState>({
  service: "",
  setService: null,
  questionIndex: 0,
  setQuestionIndex: null,
  answers: [],
  storeAnswer: null,
  startTime: "",
  endTime: "",
  setTime:null,
  customer: null,
  company: false,
  setCustomer:null,
  ticket: null,
  setTicket:null,
  operator: "",
  setOperator:null,
});

class UserProvider extends Component<{}, userState> {
  state: userState = {
    service: "",
    setService: null,
    questionIndex: 0,
    setQuestionIndex: null,
    answers: [],
    storeAnswer: null,
    startTime: "",
    endTime: "",
    setTime:null,
    customer: null,
    company: false,
    setCustomer:null,
    ticket: null,
    setTicket:null,
    operator: "",
    setOperator:null,
  };

  constructor(props: {}) {
    super(props);
    this.setService = this.setService.bind(this);
    this.setQuestionIndex = this.setQuestionIndex.bind(this);
    this.storeAnswer = this.storeAnswer.bind(this);
    this.setTime = this.setTime.bind(this);
    this.setCustomer = this.setCustomer.bind(this);
    this.setTicket = this.setTicket.bind(this);
    this.setOperator = this.setOperator.bind(this);
  }

  setService(s:string) {
    this.setState({ service: s });
  }

  setQuestionIndex(num:number) {
    this.setState({ questionIndex: num });
  }

  storeAnswer(qna:any) {
    let answerState = this.state.answers;
    let modify = false;
    answerState.forEach(function (answer:any, index:number) {
      if (answer.question === qna.question) {
        answerState[index] = qna;
        modify=true;
      }
    });
    if (modify) {
      this.setState({answers: answerState})
    }
    else {  // Append new answer instead of modifying old one
      this.setState( (prevState:any) => ({
        answers: [...prevState.answers, qna] 
      }));
    }
  }

  setTime(date1:string, date2:string) {
    this.setState({ startTime: date1, endTime:date2 });
  }

  setCustomer(user:any, company:boolean) {
    this.setState({ customer: user, company:company });
  }
  
  setTicket(t:any) {
    this.setState({ ticket: t });
  }

  setOperator(email:string) {
    this.setState({ operator: email });
  }

  render() {
    return (
      <UserContext.Provider
        value={{ ...this.state, setService: this.setService, setQuestionIndex: this.setQuestionIndex, storeAnswer: this.storeAnswer, setTime: this.setTime, setCustomer: this.setCustomer, setTicket: this.setTicket, setOperator: this.setOperator }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;