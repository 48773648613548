// General
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Theme from './assets/theme';
import UserContext from "./UserContext";
// Pages
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import QuizPage from './pages/Quiz';
import TimePage from './pages/Time';
import DetailsPage from './pages/Details';
import ConfirmPage from './pages/Confirm';
import SuccessPage from './pages/Success';

const App = () => {
  return (
    <UserContext>
      <Theme>
        <div className="App">
          <BrowserRouter>
            <Switch>
              <Route component={HomePage} path="/" exact />
              <Route component={AboutPage} path="/about" />
              <Route component={QuizPage} path="/quiz/:whichquiz" />
              <Route component={TimePage} path="/time" exact />
              <Route component={DetailsPage} path="/details" />
              <Route component={ConfirmPage} path="/confirm" exact />
              <Route component={SuccessPage} path="/success" />
            </Switch>
          </BrowserRouter>
        </div>
      </Theme>
    </UserContext>
  );
}

export default App;