import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode
}

const CardElement = styled.div`
  width:100%;
  min-height:300px;
  border-radius:8px;
  border:1px solid #f1f1f1;
  background-color:#fff;
  @media screen and (max-width:400px) {
    border-radius:0;
  }
  margin-bottom:40px;
  padding:32px;
  @media screen and (max-width:500px) {
    padding:20px;
  }
  z-index:2;
`;

const Card = (props: Props) => {
  return(
    <CardElement>
      {props.children}
    </CardElement>
  );
}

export default Card