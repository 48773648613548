import React, {useContext, useState} from 'react';
import { UserContext } from "../UserContext";
import Async from "react-async"
import styled from 'styled-components'
import readableDate from '../utils/readableDate'
import { post, PRODUCTION } from '../utils/fetch'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';

import ClipLoader from "react-spinners/ClipLoader";

let operator_email:string;
if(PRODUCTION) {
  // TODO: make selection of operator email dynamic
  operator_email = "gustaf.broman@dryft.se";
}
else {
  operator_email = "gustaf.broman@dryft.se"
}

const loadEvents = () =>
  fetch('https://dryft.tech/calendar/' + operator_email)
    .then(res => (res.ok ? res : Promise.reject(res)))
    .then(res => res.json())

const Styler = styled.div`
  position:relative;
  table {
    border-radius:2px;
  }
  h2 {
    font-size:18px !important;
  }
  thead .fc-scrollgrid-section .fc-scroller-harness .fc-scroller {
    overflow:hidden !important;
    a {
      padding-top:12px;
      padding-bottom:12px;
    }
  }
  p {
    margin: 20px 0 0 0;
    text-align:left;
    font-size:14px;
    line-height:20px;
    span {
      padding:1px;
      height:20px;
      font-size:18px;
      line-height:18px;
      margin-right:4px;
    }
    * {
      display:inline-block;
    }
  }
  .fc * {
    font-family:arial;
    text-align:center;
    color:#777;
    font-size:12px;
    border-color:#eee;
  }
  .fc-event-title {
    color:#fff;
    padding:4px 0;
    font-size:11px;
  }
  .fc h2 {
    font-size:16px !important;
    font-weight:400;
    color:#999;
    text-transform:uppercase;
  }
  .fc .fc-button-primary {
    background-color:#999;
    * {color:#fff !important;}
    border:1px solid #f1f1f1;
    outline:none;
    box-shadow:none !important;
    :active {
      background-color:#777;
    }
    .fc-icon {
      font-size:20px;
    }
  }
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 20px;
  }
  .fc-highlight {
    background-color:#afd5fd;
  }
`;

const Loader = styled.div`
  position:absolute;
  z-index:100;
  height:100%;
  width:100%;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:rgba(255,255,255,0.3);
  text-align:center;
  padding: 100px 0;
`;

const PreContainer = styled.div`
  position:relative;
  width:100%;
  padding:100px 0;
  background-color:#f1f1f1;
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.danger};
`;

const CalendarCard = () => {
  const {startTime, endTime, setTime, setOperator} = useContext(UserContext);
  var [loading, setLoading] = useState(false);
  var [e, setError] = useState("");

  var startDate = new Date()
  var endDate = new Date()
  startDate.setDate(startDate.getDate() + 1); // Book at least one day in advance
  endDate.setDate(endDate.getDate() + 14);    // Two weeks into the future;
  startDate.toLocaleDateString();
  endDate.toLocaleDateString();


  async function handleDateClick(arg:any) {
    setLoading(true)
    const start = arg.dateStr;
    const temp = Date.parse(start)
    var date = new Date(temp);
    date.setHours(date.getHours() + 6);
    const end = date.toISOString().slice(0, 16) + ":00+02:00";

    const body = {
      "start": start,
      "end":  end,
      "required_skill": "test",
      "location": "Hemvärnsgatan 15",
    }
    
    console.log(body)

    const available_operators = await post("calendar/available", body)

    console.log(available_operators)
    
    if(available_operators.length > 0) {
      setTime(body.start,body.end)
      setOperator(available_operators[0])
    }
    else {
      setTime()
      setError("Det finns ingen tillgänglig hantverkare den tiden")
    }
    setLoading(false)
  }

  return (
    <Async promiseFn={loadEvents}>
    {({ data, error, isLoading }:any) => {
      if (isLoading) return (
        <PreContainer>
          <ClipLoader
            size={100}
            color={"#266eee"}
            loading={true}
          />
        </PreContainer>
      );

      if (error) {
        return (
          <div>
            <p>Något gick fel: {error.message}</p>
            <p>Pröva ladda om sidan</p>
          </div>
        );
      }
      if (data){
        const events = data.events;
        const fullCalendarEvents = events.map( (event:any, id:number) => {
          const start_utc = event.start.dateTime
          const end_utc = event.end.dateTime

          return {
            id: "busy", 
            title: "Ej tillgänglig", 
            start: start_utc, 
            end:end_utc, 
            overlap: false,
            display: 'block',
            color: '#ff7d48',
            eventTextColor: '#fff',
            opacity:1
          }
        } )
        fullCalendarEvents.push(
          {
            id:"busy",
            title: "Boka", 
            start: "2020-07-10",
            display:"inverse-background",
            backgroundColor:"#28a745"
          }
        )

        return(
          <Styler>
            <FullCalendar
              plugins={[ dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin ]}
              // Display
              initialView={"timeGridWeek"}
              locale="sv"
              events={fullCalendarEvents}
              headerToolbar={{
                start: "title",
                center: "",
                end: "prev,next"
              }}
              firstDay={1}
              weekends={false}
              slotMinTime="08:00"
              slotMaxTime="16:00"
              weekNumbers={true}
              weekText={"v."}
              validRange={ {start: startDate, end:endDate} }
              expandRows={true}
              slotDuration={"04:00:00"}
              displayEventTime={false}
              eventDisplay={"block"}
              allDaySlot={false}
              // Selection
              selectable={true}
              selectConstraint={{
                start:"08:00",
                end:"16:00"
              }}
              longPressDelay={0}
              selectLongPressDelay={0}
              eventLongPressDelay={0}
              eventClick={function() {
                  setTime()
                  setError("Hantverkaren är redan inbokad den tiden")
                }
              }
              dateClick={(arg) => handleDateClick(arg)}
            />
            <p><span className="material-icons">access_time</span><b>Datum:</b> {startTime && endTime ? readableDate(startTime) + "-" + readableDate(endTime).slice(11,16) : e ? <ErrorMessage>{e}</ErrorMessage>:"vänligen klicka på en tid i kalendern" }</p>
            {loading ? <Loader>
              <ClipLoader
                size={100}
                color={"#266eee"}
                loading={true}
              />
            </Loader> : ""}
          </Styler>
        )
      }
      return null
    }}
  </Async>
  )
}

export default CalendarCard