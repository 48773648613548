import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const L = styled.h2`
    display:inline-block;
    height: 100%;
    font-weight:400;
    font-size:22px;
    overflow:hidden;
    margin:0;
    line-height:40px;
`;
const LogoBlue = styled.span`
    font-weight: 600;
    color:#00695c;
`;

const DryftNowLogo = () => {
  return (
    <Link to="/">
      <L>
        småjobb<LogoBlue>.app</LogoBlue>
      </L>
    </Link>
  );
}

export default DryftNowLogo;
