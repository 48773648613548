import React, { useContext } from 'react';
import styled from 'styled-components';
import Nav from '../components/Nav';
import CategoryCard from '../components/Category';
import { UserContext } from "../UserContext";
import bannerImage from "../assets/images/carpenter.jpg"

const Container = styled.div`
  margin:0px auto 20px auto;
  text-align: center;
`;

const IntroText = styled.div`
  background-image:url(${bannerImage});
  background-size:cover;
  background-position:center;
  width:100%;
  height:auto;
  h1 {
    font-size:52px;
    color:#fff;
    background: rgba(0,0,0,0.05);
    line-height:220px;
    text-shadow: -2px 2px rgba(0,0,0,0.1);
    @media screen and (max-width:750px) {
      font-size:46px;
    }
    @media screen and (max-width:662px) {
      font-size:42px;
    }
    @media screen and (max-width:600px) {
      font-size:36px;
    }
    @media screen and (max-width:512px) {
      font-size:30px;
    }
    @media screen and (max-width:435px) {
      line-height:120px;
      font-size:26px;
    }
    @media screen and (max-width:390px) {
      font-size:22px;
    }
    @media screen and (max-width:315px) {
      font-size:20px;
    }
    @media screen and (max-width:290px) {
      font-size:18px;
    }
  }
`;

const HomePage = () => {
  const { setService, setQuestionIndex } = useContext(UserContext);

  return (
    <React.StrictMode>
      <Nav />
      <div className="mw">
        <IntroText><h1 className="ap">Vad behöver du hjälp med?</h1></IntroText>
      </div>
      <div className="ap mw">
        <Container>
          <CategoryCard category="kranar" onClick={() => {setService("Service kran"); setQuestionIndex(0)} } />
          <CategoryCard category="vitvaror" onClick={() => {setService("Vitvara"); setQuestionIndex(0)} } />
          <CategoryCard category="avlopp" onClick={() => {setService("Stopp i avlopp"); setQuestionIndex(0)} } />
          <CategoryCard category="vuk" onClick={() => {setService("Installera vattenutkastare (VUK)"); setQuestionIndex(0)} } />
          <CategoryCard category="vvb" onClick={() => {setService("Utbyte av varmvattenberedare (VVB)"); setQuestionIndex(0)} } />
          <CategoryCard category="värmesystem" onClick={() => {setService("Service värmesystem"); setQuestionIndex(0)} } />
        </Container>
      </div>
    </React.StrictMode>
  );
}

export default HomePage