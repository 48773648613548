import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Btn = styled.button`
    height: 40px;
    width:100%;
    max-width:300px;
    padding:0px 30px;
    margin: 32px auto;
    text-align:center;
    background-color: ${props => props.theme.bg};
    color: ${props => props.theme.fg};
    border-radius:12px;
    user-select:none;
    cursor:pointer;
    &:hover {
        opacity:0.9;
        box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    }
    :disabled {
        opacity:0.32;
        cursor:default;
    }
`;

// Define our `fg` and `bg` on the theme
const defaultTheme = {
    fg: "#ffffff",
    bg: "#007bff"
};
  
// This theme swaps `fg` and `bg`
const lightTheme = ({
    fg: "#131313",
    bg: "#fff"
});
  

const BtnText = styled.p`
    display:inline-block;
    line-height:40px;
    font-size:16px;
    font-weight:400;
    color:inherit;
    margin:0 5px;
`;

const BtnIcon = styled.i`
    line-height:40px;
    color: inherit;
    margin:0 5px;
    color:inherit;
`;

function getIcon(type: string):Object {
    if (type==="login") {
        return <BtnIcon className="material-icons">login</BtnIcon>;
    }
    else if (type==="add") {
        return <BtnIcon className="material-icons">add_circle_outline</BtnIcon>;
    }
    else if (type==="next") {
        return <BtnIcon className="material-icons">navigate_next</BtnIcon>;
    }
    else {
        return <BtnIcon className="material-icons"></BtnIcon>
    }
}

const Button = ( {text, type, after, linkTo, light, func, disabled} : any ) => {
  return (
    <NavLink to={linkTo && !disabled ? linkTo : "#"}>
        <Btn type="submit" theme={light ? lightTheme : defaultTheme} onClick={disabled ? "" : func} disabled={disabled ? disabled : false}>
            {after?"":getIcon(type)}
            <BtnText>{text}</BtnText>
            {after?getIcon(type):""}
        </Btn>
    </NavLink>
  );
}

export default Button;
