import React, { useContext } from 'react';
import styled from 'styled-components';
import Nav from '../components/Nav';

const Container = styled.div`
  margin:20px auto;
  max-width:420px;
  p {
    margin-bottom:20px;
  }
`;

const AboutPage = () => {
  return (
    <React.StrictMode>
      <Nav />
      <Container className="ap mw">
        <h2>Om oss</h2>
        <p>
          Småjobb.app erbjuder transparent och smidig hjälp inom VVS. Vi erbjuder service för kranar, vitvaror, avlopp, vattenutkastare, varmvattenberedare och värmesystem.
        </p>
        <h2>Hur går det till?</h2>
        <p>
          Hela bokningsprocessen är automatiserad.
        </p>
        <p>
          Du klickar på den tjänst som du behöver, svarar på frågorna för att beskriva problemet, bokar in en tid som passar och i slutet får du ett prisförslag.
        </p>
      </Container>
    </React.StrictMode>
  );
}

export default AboutPage