import React, {useContext} from 'react';
import styled from 'styled-components';
import ProgressNav from '../components/ProgressNav'
import QuizForm from '../components/QuizForm'
import kran from '../assets/question_bank/kran.json';
import avlopp from '../assets/question_bank/avlopp.json';
import varme from '../assets/question_bank/varme.json';
import vitvara from '../assets/question_bank/vitvara.json';
import vuk from '../assets/question_bank/vuk.json';
import vvb from '../assets/question_bank/vvb.json';
import { useHistory } from "react-router-dom";
import { UserContext } from "../UserContext";
import { useParams } from "react-router";

const Section = styled.div`
  width:100vw;
  height:100vh;
  display:flex;
  flex-direction:column;
`;

const QuizPage = () => {
  const {questionIndex, setQuestionIndex, storeAnswer, service, setService} = useContext(UserContext);
  const history = useHistory();
  
  let { whichquiz } = useParams();
  let questionBank;
  let question_battery:any = [];
  let length:any;
  let question:any;

  switch( whichquiz ){
    case "kran":
      questionBank = {kran};
      question_battery = questionBank.kran;
      if (!service || service === "") {
        setService("Service kran");
      }
      break;
    case "vitvara":
      questionBank = {vitvara};
      question_battery = questionBank.vitvara;
      if (!service || service === "") {
        setService("Vitvara");
      }
      break;
    case "avlopp":
      questionBank = {avlopp};
      question_battery = questionBank.avlopp;
      if (!service || service === "") {
        setService("Stopp i avlopp");
      }
      break;
    case "vuk":
      questionBank = {vuk};
      question_battery = questionBank.vuk;
      if (!service || service === "") {
        setService("Installera vattenutkastare (VUK)");
      }
      break;
    case "vvb":
      questionBank = {vvb};
      question_battery = questionBank.vvb;
      if (!service || service === "") {
        setService("Utbyte av varmvattenberedare (VVB)");
      }
      break;
    case "varme":
      questionBank = {varme};
      question_battery = questionBank.varme;
      if (!service || service === "") {
        setService("Service värmesystem");
      }
      break;
  }

  length = question_battery.length;
  question = question_battery[questionIndex];

  const nextQuestion = (answer:any) => {
    question.answer = answer;
    storeAnswer(question)
    if (questionIndex + 1 < length) {
      const next = questionIndex + 1;
      setQuestionIndex(next)
    }
    else {
      history.push("/time")
    }
  }

  const previousQuestion = () => {
    if (questionIndex + 1 > 1) {
      const next = questionIndex - 1;
      setQuestionIndex(next)
    }
    else {
      history.push("/")
    }
  }

  return(
    <Section>
      <ProgressNav />
      {question ? <QuizForm question={question} questionIndex={questionIndex} length={length} nextQuestion={nextQuestion} previousQuestion={previousQuestion} />: ""}
    </Section>
  );
}

export default QuizPage