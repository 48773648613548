import React from 'react';
import styled from 'styled-components';
import Nav from '../components/Nav';
import LiamImage from '../assets/images/liam.jpg';

const SuccessText = styled.div`
  max-width:300px;
  margin:20px auto;
  text-align: center;
  h1 {
    font-size:42px;
    margin-top:20px;
  }
  p {
    font-size:18px;
  }
  img {
    width:180px;
    height:180px;
    object-position: center -30px;
    object-fit:cover;
    filter:contrast(110%);
    margin: 20px auto 10px auto;
    border-radius:50%;
  }
  p {
    margin-bottom:20px;
  }
  ul {
    max-width:240px;
    margin: -18px auto 0 auto;
    text-align:left;
    list-style-type:disc;
  }
`;

const HomePage = () => {
  return (
    <React.StrictMode>
      <Nav />
      <div className="ap mw">
        <SuccessText>
          <img src={LiamImage} alt="Yipee!" />
          <h1>Yipee!</h1>
          <p>Din beställning har gått igenom och följande hantverkare har blivit bokad:</p>
          <p><b>Liam Newman</b></p>
          <ul>
            <li>Telnr: 070-899-2573</li>
            <li>Mejl: liam.newman@dryft.se</li>
          </ul>
        </SuccessText>
      </div>
    </React.StrictMode>
  );
}

export default HomePage