import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Card = styled.div`
    position:relative;
    overflow:hidden;
    display: inline-block;
    height: auto;
    width:calc(33% - 20px);
    margin:10px;
    background-color:#fff;
    border:1px solid #dadce0;
    border-radius:12px;
    &:hover {
        border:1px solid #ccc;
        span {
            transform:scale(1.1);
        }
    }
    @media screen and (max-width:660px) {
        width:calc(50% - 10px);
        margin:5px;
    }
`;

const CardImage = styled.div`
    width:100%;
    height:auto;
    object-fit:cover;
    background-color:#fff;
    span {
        transition: 0.2s ease all;
        object-fit:contain;
        text-align:center;
        line-height:110px;
        padding:10px;
        font-size:64px;
        color:#00695c;
    }
`;

const CardTitle = styled.h3`
    width:100%;
    line-height:32px;
    background-color:#fff;
    color:#000;
    font-size:20px;
    padding:12px;
    text-align:left;
    margin:0;
    border-top:1px solid #dadce0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media screen and (max-width:500px) {
        font-size:18px;
    }
    @media screen and (max-width:384px) {
        font-size:16px;
        line-height:20px;
    }
`;

const CategoryCard = ( { category, ...rest } : any ) => {
    let title;
    let icon;
    let linkTo = "/";
    if (category === "kranar") {
        title = "Kranar";
        icon = "plumbing";
        linkTo = "/quiz/kran";
    }
    else if (category === "vitvaror") {
        title = "Vitvaror";
        icon = "kitchen";
        linkTo = "/quiz/vitvara";
    }
    else if (category === "avlopp") {
        title = "Stopp i avlopp";
        icon = "wc";
        linkTo = "/quiz/avlopp";
    }
    else if (category === "vuk") {
        title = "Vattenutkastare";
        icon = "waves";
        linkTo = "/quiz/vuk";
    }
    else if (category === "vvb") {
        title = "Varmvattenberedare";
        icon = "hot_tub";
        linkTo = "/quiz/vvb";
    }
    else if (category === "värmesystem") {
        title = "Värmesystem";
        icon = "fireplace";
        linkTo = "/quiz/varme";
    }

    return (
        <NavLink to={linkTo} {...rest} >
            <Card>
                <CardImage>
                    <span className="material-icons">{icon}</span>
                </CardImage>
                <CardTitle>{title}</CardTitle>
            </Card>
        </NavLink>
    );
}

export default CategoryCard;
