import React from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import DryftNowLogo from './Logo';

const Header = styled.header`
  width:100%;
  height:80px;
  margin-bottom:40px;
  background-color:#fff;
  border-bottom:1px solid #f1f1f1;
  @media (max-width:400px) {
    margin-bottom:32px;
  }
`;

const Nav = styled.div`
  display:flex;
  height:100%;
  padding-top: 20px;
  padding-bottom:20px;
  @media screen and (max-width:900px) {
    div a {
      display:none;
    }
    div a.active {
      display:block;
    }
  }
  @media screen and (max-width:360px) {
    h2 {
      display:none;
    }
  }
`;

const Scroller = styled.div`
  position:relative;
  flex: 1;
  height:40px;
  width:auto;
  display:inline-block;
  overflow:hidden;
  overflow-x:auto;
  white-space: nowrap;
  margin-left:10px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

const CheckPoint = styled.div`
  display:inline-block;
  height:42px;
  margin-left:10px;
  border-left:1px solid #f1f1f1;
  opacity:0.5;
  div {
    display:inline-block;
    background-color:#eee;
    margin:3px;
    width:36px;
    height:36px;
    border-radius:50%;
    margin-right:12px;
    margin-left:20px;
    p {
      line-height:36px;
      font-size:18px;
      color: #666;
      font-weight:900;
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-41%, -50%);
    }
  }
  h3 {
    display:inline-block;
    line-height:42px;
    margin-right:10px;
    font-size:17px;
    letter-spacing:0em;
    color:#666;
    font-weight:400;
  }
  @media screen and (max-width:360px) {
    margin:0;
    border-left:none;
    div{
      margin-left:0;
    }
  }
`;

const ProgressNav = ({step}:any) => {
  return (
    <Header className="ap">
      <Nav className="mw">
        <DryftNowLogo />
        <Scroller>
          <NavLink to="/quiz/" activeClassName={"active"} onClick={(e:any) => e.preventDefault()}>
            <CheckPoint>
              <div>
                <p>1.</p>
              </div>
              <h3>Beskriv problemet</h3>
            </CheckPoint>
          </NavLink>
          <NavLink to="/time" activeClassName={"active"} onClick={(e:any) => e.preventDefault()}>
            <CheckPoint>
              <div>
                <p>2.</p>
              </div>
              <h3>Boka tid</h3>
            </CheckPoint>
          </NavLink>
          <NavLink to="/details" activeClassName={"active"} onClick={(e:any) => e.preventDefault()}>
            <CheckPoint>
              <div>
                <p>3.</p>
              </div>
              <h3>Fyll i uppgifter</h3>
            </CheckPoint>
          </NavLink>
          <NavLink to="/confirm" activeClassName={"active"} onClick={(e:any) => e.preventDefault()}>
            <CheckPoint>
              <div>
                <p>4.</p>
              </div>
              <h3>Bekräfta pris</h3>
            </CheckPoint>
          </NavLink>
        </Scroller>
      </Nav>
    </Header>
  );
}

export default ProgressNav;